<template>
    <section class="my-2">
        <!-- Grafica Ventas Mensuales -->
        <div class="row mx-3 pt-4 align-items-center px-4 bg-white br-t-12 py-2 text-general f-18 f-600">
            <div class="col-auto my-xl-0 my-lg-3 my-md-3 my-sm-3 px-0">
                Ventas y pedidos día a día
            </div>
            <div class="col-xl col-lg-10 px-lg-0 px-md-0 ml-xl-0 ml-lg-auto ml-md-auto md-sm-auto">
                <div class="row mx-xl-5 mx-lg-0 mx-md-0 justify-content-xl-start justify-content-lg-between border-bottom">
                    <div
                    v-for="(data, i) in meses" :key="i"
                    class="col-auto p-2 f-14" :class="data.activo ? (mes == data.fecha ? 'cr-pointer border-b-red text-gr-red' :'cr-pointer text-muted2') :'text-gris' "
                    @click="seleccionarMes(data)"
                    >
                        {{ data.mes }}
                    </div>
                </div>
            </div>
        </div>
        <div>
            <cargando v-if="loading1" />
            <chartBarras :datos="datos_diarios" :chart="chart_diario" />
        </div>
        <!-- Grafica Ventas y Pedidos dia a dia -->
        <!-- Grafica Ventas Mensuales -->
        <div class="row mx-3 px-5 text-general bg-white br-t-12 mt-4 py-3 f-18 f-600">
            Ventas mensuales durante los últimos 12 meses
        </div>
        <div>
            <cargando v-if="loading2" />
            <chartBarras :datos="datos_mensual" :chart="chart_meses" />
        </div>
        <!-- Grafica Ventas Mensuales -->
        <div class="row mx-3 px-3 mt-3 py-3 bg-white br-t-12 text-general f-18 f-600">
            Ticket promedio de los últimos 12 meses
        </div>
        <!-- Calificaciones pedidos permisos 12 -->
        <div class="position-relative">
            <cargando v-if="loading2" />
            <chartBarras :datos="datos_ultimos_promedio" :chart="chart_meses_promedio" />
        </div>
        <!-- Grafica Ventas Mensuales -->
        <div class="row mx-3 px-3 py-3 bg-white br-t-12 mt-3 text-general f-18 f-600">
            Calificaciones promedio de los pedidos de los últimos 12 meses
        </div>
        <!-- Calificaciones pedidos permisos 12 -->
        <div>
            <cargando v-if="loading3" />
            <chartBarras :datos="datos_ultimos" :chart="chart_ultimos" />
        </div>
        <!--  -->
        <div class="row mx-0 py-3 my-3">
            <cargando v-if="loading4" />
            <div class="col-xl-auto col-lg-12 col-md-12 col-sm-12 box-left my-xl-0 my-lg-2 my-md-2 my-sm-3">
                <div class="br-12 shadow bg-white text-general px-2 d-middle-center flex-column" style="width:125px;height:115px;">
                    <p class="mb-0 text-center f-18 f-600">
                        {{ favoritos_productos }}
                    </p>
                    <p class="mb-0 text-center f-15 px-1">
                        Productos marcados como favoritos
                    </p>
                </div>
            </div>
            <template v-if="favoritos.length">
                <div class="content-cards col-xl col-lg-12 col-md-12 col-sm-12 col-12 my-xl-0 my-lg-3 my-md-3 my-sm-3 my-3 px-0" style="width: calc(100% - 440px);">
                    <div class="d-flex overflow-hidden custom-scroll justify-center">
                        <div v-for="(data,d) in favoritos" v-show="d < calcPantalla" :key="d" class="col-auto position-relative">
                            <el-tooltip v-if="data.estado == 0" placement="bottom" content="Inactivo" effect="light">
                                <div class="position-absolute z-1 rounded-circle bg-icon d-middle-center" style="right:20px;">
                                    <i class="icon-eye-off f-20 text-white" />
                                </div>
                            </el-tooltip>
                            <el-tooltip v-if="data.stock" placement="bottom" content="Stock bajo" effect="light">
                                <div class="position-absolute z-1 rounded-circle bg-icon d-middle-center" style="right:20px;">
                                    <i class="icon-package-down f-20 text-white" />
                                </div>
                            </el-tooltip>
                            <el-tooltip placement="bottom" :content="data.nombre" effect="light">
                                <div class="border br-12 d-middle-center bg-white" style="width:115px;height:115px;object-fit:cover;">
                                    <img class="w-50" :src="data.foto_firmada" alt="" />
                                </div>
                            </el-tooltip>
                            <p class="mb-0 text-general f-600 f-18 text-center">
                                {{ data.cantidad }}
                            </p>
                        </div>
                    </div>
                </div>
            </template>
            <!-- Sin datos -->
            <div v-if="!favoritos.length" class="col position-relative">
                <div class="row justify-center">
                    <div class="borde d-middle-center p-2 br-12 sin_datos">
                        <img height="65" src="/img/no-imagen/mercado.svg" alt="" />
                    </div>
                </div>
                <div class="row justify-center f-15 text-muted2">
                    Aun no se han registrado ventas
                </div>
            </div>
            <!-- Sin datos -->
            <div class="col-xl-auto col-lg-12 col-md-12 col-sm-12 box-right my-xl-0 my-lg-2 my-md-2 my-sm-3">
                <div class="br-12 bg-white shadow text-general px-xl-3 px-lg-2 px-md-2 py-4" style="width:247px;height:115px;">
                    <p class="mb-0 text-center order-2">
                        {{ $config.cliente }} que te marcaron como favorito
                    </p>
                    <p class="mb-0 f-20 f-600 text-center order-1">
                        {{ favoritos_clientes }} <i class="icon-heart-empty" />
                    </p>
                </div>
            </div>
        </div>
        <!--  -->
    </section>
</template>
<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import echarts from 'echarts';
Vue.component('echarts',echarts);
let colorGeneral = getComputedStyle(document.documentElement).getPropertyValue('--color-db');
let colorGeneral2 = getComputedStyle(document.documentElement).getPropertyValue('--color-general');
import Metricas from "~/services/metricas";
export default {
    props: {
        datos : {
            type: Boolean,
            default: true
        },
        tipoFecha : {
            type: Number,
            default: 1
        }
    },
    data(){
        return{
            loading1: true,
            loading2: true,
            loading3: true,
            loading4: true,
            mes: '',
            meses: [],
            datos_mensual: [
                { nombre: 'Ventas totales', valor: '$25.564.654', color: 'bg-gr-general', formato:1 },
                { nombre: 'Ventas promedio al mes', valor: '$234.500', color: 'bg-gr-pink', formato:1 },
                { nombre: 'Pedidos totales', valor: '1.542', color: 'bg-gr-red', formato:2 },
                { nombre: 'Pedidos promedio al mes', valor: '84', color: 'bg-gr-purple', formato:2 }
            ],
            datos_diarios: [
                { nombre: 'Ventas totales', valor: '$25.564.654', color: 'bg-gr-red', formato:1 },
                { nombre: 'Ventas promedio al día', valor: '$234.500', color: 'bg-gr-general', formato:1 },
                { nombre: 'Día de mayor venta', valor: '$287.000', fecha: '12 de Noviembre', color: 'bg-dark', formato:1 }
            ],
            datos_ultimos: [
                { nombre: 'Mes con mejor calificiación', valor: '0', fecha: 'Ene. 2020', color: 'bg-gr-general', formato:2 },
                { nombre: 'Mes con peor calificiación', valor: '0', fecha: 'Oct. 2019', color: 'bg-gr-red', formato:2 }
            ],
            datos_ultimos_promedio: [
                { nombre: 'Ticket promedio', valor: '0', color: 'bg-gr-general', formato: 1 },
                { nombre: 'Mes con Ticket promedio más alto', valor: '0', fecha: 'Oct. 2019', color: 'bg-gr-red', formato: 1 }
            ],
            chart_meses_promedio: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['mes', 'promedio'],
                    source: []
                },
                series: [
                    {
                        name: 'Ticket',
                        type: 'line',
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: colorGeneral2
                        },
                    }
                ]
            },
            chart_meses: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                legend: {
                    data:['Pedidos', 'Ventas']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        name: 'Valor',
                        type: 'value',
                        scale: true,
                    },
                    {
                        name: 'Cantidad',
                        type: 'value',
                        scale: true,
                    }
                ],
                dataset:{
                    dimensions:['mes', 'total','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Ventas',
                        type: 'bar',
                        // xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: colorGeneral
                        },
                    },
                    {
                        name: 'Pedidos',
                        type: 'line',
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: colorGeneral2
                        },
                    }
                ]
            },
            chart_diario: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                legend: {
                    data:['Pedidos', 'Ventas']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        name: 'Valor',
                        type: 'value',
                        scale: true,
                    },
                    {
                        name: 'Cantidad',
                        type: 'value',
                        scale: true,
                    }
                ],
                dataset:{
                    dimensions:['dia', 'total','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Ventas',
                        type: 'bar',
                        // xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: colorGeneral
                        },
                    },
                    {
                        name: 'Pedidos',
                        type: 'line',
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: colorGeneral2
                        },
                    }
                ]
            },
            chart_ultimos: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                legend: {
                    data:['Cantidad', 'Calificaciones']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        name: 'Calificación',
                        type: 'value',
                        scale: true,
                    },
                    {
                        name: 'Cantidad',
                        type: 'value',
                        scale: true,
                    }
                ],
                dataset:{
                    dimensions:['mes', 'total','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Calificaciones',
                        type: 'bar',
                        // xAxisIndex: 1,
                        yAxisIndex: 1,
                        itemStyle: {
                            color: colorGeneral
                        },
                    },
                    {
                        name: 'Cantidad',
                        type: 'line',
                        symbol:'circle',
                        symbolSize: 10,
                        itemStyle: {
                            color: colorGeneral2
                        },
                    }
                ]
            },
            favoritos: [],
            favoritos_productos: null,
            favoritos_clientes: null,
            ventas_mensuales_created: null,
            ventas_mensuales_entregas: null,
            ventas_diarias_created: null,
            ventas_diarias_entregas: null,
            calificaciones_mensuales_created: null,
            calificaciones_mensuales_entregas: null,
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
        calcPantalla(){
            if (screen.width < 1400){
                return 4
            }else { return 6 }
        },
        id_tienda(){
            let id_tienda = null
            if (this.$route.params.id_tienda){
                id_tienda = this.$route.params.id_tienda
            } else {
                id_tienda = this.$usuario.tienda.id
            }

            return id_tienda
        },
    },
    watch: {
        tipoFecha(){
            this.calcularVentasMensuales()
            this.calcularVentasDiarias()
            this.calcularCalificaciones()
        },
    },
    methods: {
        cargando(){
            this.loading1 = true
            this.loading2 = true
            this.loading3 = true
            this.loading4 = true
        },
        async montar(){
            if (this.id_cedis != null){
                await this.toPromise(this.ventas_mensuales)
                await this.toPromise(this.ventas_diarias)
                await this.toPromise(this.calificaciones_mensuales)
                await this.toPromise(this.productos_favoritos)
            }
        },
        async ventas_mensuales(){
            try {
                let params = {
                    id_tienda: this.id_tienda,
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis
                }
                const { data } = await Metricas.ventas_mensuales(params)
                this.ventas_mensuales_created = data.data.pedidos_created
                this.ventas_mensuales_entregas = data.data.pedidos_entregas

                let datos = this.tipoFecha == 1 ? this.ventas_mensuales_entregas : this.ventas_mensuales_created

                this.meses = datos.pedidos.map(p=>{
                    let m = {}
                    m = {
                        mes: p.mes,
                        activo: p.cantidad > 0,
                        fecha: p.fecha
                    }
                    if (p.cantidad > 0){
                        this.mes = p.fecha
                    }
                    return m
                })

                this.calcularVentasMensuales()

            } catch (error){
                this.error_catch(error)
            } finally{
                this.loading2 = false
            }
        },
        calcularVentasMensuales(){
            try {

                let datos = this.tipoFecha == 1 ? this.ventas_mensuales_entregas : this.ventas_mensuales_created
                this.meses = datos.pedidos.map(p=>{
                    let m = {}
                    m = {
                        mes: p.mes,
                        activo: p.cantidad > 0,
                        fecha: p.fecha
                    }
                    // if (p.cantidad > 0){
                    //     // this.mes = p.fecha
                    // }
                    return m
                })
                this.chart_meses.dataset.source = datos.pedidos
                this.datos_mensual[0].valor = datos.total
                this.datos_mensual[1].valor = datos.total_promedio
                this.datos_mensual[2].valor = datos.cantidad
                this.datos_mensual[3].valor = datos.cantidad_promedio

                this.chart_meses_promedio.dataset.source = datos.pedidos
                this.datos_ultimos_promedio[0].valor = datos.promedio
                this.datos_ultimos_promedio[1].valor = datos.max_promedio.promedio
                this.datos_ultimos_promedio[1].fecha = datos.max_promedio.mes

            } catch (error){
                this.error_catch(error)
            }
        },
        async ventas_diarias(){
            try {
                let params = {
                    id_tienda: this.id_tienda,
                    fecha: this.mes,
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis
                }
                const { data } = await Metricas.ventas_diarias(params)
                this.ventas_diarias_created = data.data.pedidos_created
                this.ventas_diarias_entregas = data.data.pedidos_entregas

                this.calcularVentasDiarias()


            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading1 = false
            }
        },
        calcularVentasDiarias(){
            try {

                let datos = this.tipoFecha == 1 ? this.ventas_diarias_entregas : this.ventas_diarias_created
                this.chart_diario.dataset.source = datos.pedidos
                this.datos_diarios[0].valor = datos.total
                this.datos_diarios[1].valor = datos.total_promedio
                this.datos_diarios[2].valor = datos.dia_max ? datos.dia_max.total : 0
                this.datos_diarios[2].fecha = datos.dia_max ? datos.dia_max.dia : 'No registra'

            } catch (error){
                this.error_catch(error)
            }
        },
        async calificaciones_mensuales(){
            try {
                let params = {
                    id_tienda: this.id_tienda,
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis
                }
                const { data } = await Metricas.calificaciones_mensuales(params)

                this.calificaciones_mensuales_created = data.data.pedidos_created
                this.calificaciones_mensuales_entregas = data.data.pedidos_entregas

                this.calcularCalificaciones()

            } catch (error){
                this.error_catch(error)
            } finally{
                this.loading3 = false
            }
        },
        calcularCalificaciones(){
            try {

                let datos = this.tipoFecha == 1 ? this.calificaciones_mensuales_entregas : this.calificaciones_mensuales_created
                this.chart_ultimos.dataset.source = datos.pedidos
                this.datos_ultimos[0].valor = datos.max ? datos.max.total : 0
                this.datos_ultimos[0].fecha = datos.max ? datos.max.mes : 'No registra'
                this.datos_ultimos[1].valor = datos.min ? datos.min.total : 0
                this.datos_ultimos[1].fecha = datos.min ? datos.min.mes : 'No registra'

            } catch (error){
                this.error_catch(error)
            }
        },
        async productos_favoritos(){
            try {
                let params = {
                    id_tienda: this.id_tienda,
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis
                }
                const { data } = await Metricas.productos_favoritos(params)
                data.data.favoritos.map(f=>{
                    f.stock = f.stock_uso == 1 ? (f.stock_minimo >= Number(f.stock_actual)) : false
                })
                this.favoritos_productos = data.data.favoritos_productos
                this.favoritos = data.data.favoritos
                this.favoritos_clientes = data.data.favoritos_clientes

            } catch (error){
                this.error_catch(error)
            } finally{
                this.loading4 = false
            }
        },
        seleccionarMes(data){
            data.activo ? this.mes = data.fecha : ''
            this.ventas_diarias()
        }
    }
}
</script>
<style lang="scss" scoped>
.bg-icon{
    width: 28px;
    height: 28px;
    background: #000000a3;
}


/* Media Queries */

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

    .box-left{
        display: flex;
        justify-content: center;
        div{
            width: 335px !important;
            height: 50px !important;
        }
    }
    .box-right{
        display: flex;
        justify-content: center;
        div{
            width: 335px !important;
            height: 50px !important;
        }
    }
}
@media only screen  and (max-width : 1224px) {
    .box-left{
        display: flex;
        justify-content: center;
        padding: 0px !important;
        div{
            display: flex;
            flex-direction: inherit !important;
            padding: 0px !important;
            width: 380px !important;
            height: 50px !important;
        }
    }
    .content-cards{
        div{
            overflow: auto !important;
        }
    }
    .box-right{
        display: flex;
        justify-content: center;
        div{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px !important;
            width: 380px !important;
            height: 50px !important;
        }
    }
}
</style>
